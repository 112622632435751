






















import { defineComponent, reactive, ref } from '@vue/composition-api';
import { InputBox } from '@/components';

import { AxiosError } from 'axios';

import { RountName } from '@/router';
import { authSearvice } from '@/services';
import { removeToken } from '@/utils';

export default defineComponent({
  name: 'AuthLogin',
  components: {
    InputBox,
  },
  setup(_, { root }) {
    const isLoading = ref(false);
    const form = reactive({
      email: '',
    });

    async function onSubmitClicked() {
      if (!form.email) {
        alert('이메일을 입력해 주세요.');
        return;
      }

      if (isLoading.value) return;

      isLoading.value = true;

      try {
        await authSearvice.resetPassword(form.email);

        alert('이메일로 초기화된 비밀번호가 전송되었습니다.');

        removeToken();

        root.$router.push({
          name: RountName.AuthLogin,
          query: {
            from: RountName.AuthPasswordReset,
          },
        });
      } catch (e) {
        // const error = e as AxiosError;
        // if (error.response?.data.message) {
        //   alert(error.response.data.message);
        // }
      } finally {
        isLoading.value = false;
      }
    }

    return {
      RountName,

      form,

      onSubmitClicked,
    };
  },
});
